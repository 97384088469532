import {Observable} from 'rxjs/internal/Observable';

/**
 * Loading state of a Collection at the store.
 */
export enum CollectionState {
  /**
   * Store is not initialized by the first call, yet. Static return value, until the
   * context is set with valid values.
   */
  Undefined = 'Undefined',

  /**
   * Store is initialized and first call is ongoing.
   */
  Initialized = 'Initialized',

  /**
   * First call is successfully made.
   */
  Established = 'Established',

  /**
   * Store is completed. The collection has reached their end by removed next token.
   */
  Completed = 'Completed',
}

/**
 * Collection API.
 */
export abstract class CollectionController<T> {
  public data: Observable<T[]>;

  /**
   * Count of records of the context without filters.
   * Delivered by meta property 'records' of API response.
   */
  public size: Observable<number>;

  /**
   * Total count of current context e.g. search matches.
   * Delivered by meta property 'total' of API response.
   */
  public total: Observable<number>;

  /**
   * Returns true if another page of the collection can be loaded.
   * Corresponds to existence of next token and loading == false.
   */
  abstract hasNext: Observable<boolean>;

  /**
   * Returns true if the collection has an ongoing API call.
   */
  public loading: Observable<boolean>;

  /**
   * Loads the next items by next token.
   */
  abstract loadNext(): void;

  /**
   * Searches the collection by backend search.
   *
   * @param term
   */
  abstract search(term: string): void;

  /**
   * Discards a collection and does a cleanup.
   */
  abstract discard(): void;
}

/**
 * Context of a resource collection.
 * Mainly a subset of values of the store.
 * The context must identify a resource subset in a unique way.
 */
export abstract class CollectionContext<T> {
  abstract getCollectionId(): string;
}

/**
 * Collection dictionary at the store.
 */
export interface ICollectionStore {
  /**
   * Collection loading state. See CollectionState for further details.
   * See examples at the ProcessArtifact reducer.
   */
  state: CollectionState;

  /**
   * Collection has currently an ongoing API call to fetch items.
   */
  loading: boolean;

  /**
   * Size of records of the collection including those not being fetched, yet.
   */
  size: number;

  /**
   * Total of items inside collection matching a certain filter/search.
   *
   * NOTE: Unused: Concept needed to retrieve the total if no items are
   * delivered: See problem, that the count is returned by items created by the builder.
   */
  total: number;

  /**
   * Next token.
   * Only available after first call if further items are in the scope.
   * The property is nullified on last page call of the resource scope.
   */
  next: string;

  /**
   * IDs of already fetched items of the collection.
   * The IDs can be matched with entities of the store and can be returned by an according
   * selector.
   *
   * See ProcessArtifactSelectors for an example.
   */
  ids: string[];
}

/**
 * Interface for resources supporting Collections.
 */
export interface ICollectable {
  /**
   * Validator if item item can be added to the collection afterwards.
   *
   * Example: ProcessArtifact store: On new uploads all existing collections must be rechcked
   * if the item can be added outside of regular resource fetching.
   *
   * @param string
   */
  hasValidContext(string): boolean;
}
