import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CollectorCategorySelectors} from '../collector-category';
import {CollectorItemSelectors} from '../collector-item';
import {ExternalAccessSelectors} from '../external-access';
import {ProcessSelectors} from '../process';
import {adapter, State} from './process-artifact.state';
import {getAllTasksOfProcess} from '../task/task.selectors';
import {TaskResourceSelectors} from '../task-resource';
import {Process} from 'app/+store/_legacy/api/models/process';
import {ProcessArtifact} from './process-artifact';
import {CollectionState} from '../collection/collection';

export const stateKey = 'process-artifact';
const getProcessArtifactState = createFeatureSelector<State>(stateKey);

export const {
  selectEntities: getProcessArtifactEntities,
  selectAll: getAllProcessArtifacts,
} = adapter.getSelectors(getProcessArtifactState);

export const getLoadingState = createSelector(
  getProcessArtifactState,
  state => state.loading
);

export const transferOngoing = createSelector(
  getProcessArtifactState,
  state => state.transfering
);

export const getProcessArtifactById = (id: string) => createSelector(
  getProcessArtifactEntities,
  entities => entities[id]
);

export const getProcessArtifactByIds = (ids: string[]) => createSelector(
  getProcessArtifactEntities,
  entities => {
    const artifacts = [];
    ids.forEach(id => {
      if (entities[id]) {
        artifacts.push(entities[id]);
      }
    });
    return artifacts;
  }
);

const getProcessArtifactsProcessMap = createSelector(
  getAllProcessArtifacts,
  artifacts => {
    const artifactProcessMap = {}
    artifacts.forEach(a => {
      if (a.processIds && a.processIds.length) {
        a.processIds.forEach(pid => {
          if (!artifactProcessMap[pid]) {
            artifactProcessMap[pid] = [];
          }
          artifactProcessMap[pid].push(a);
        });
      }
    })
    return artifactProcessMap;
  }
);

export const getProcessArtifactsOfProcess = (id: string) => createSelector(
  getProcessArtifactsProcessMap,
  artifactPidMap => {
    if (!id) return [];
    // VEEEERy inefficient.
    // return artifacts.filter(artifact => !!artifact.processIds.find(pid => pid === id))
    const res = artifactPidMap[id];
    return res ? res : [];
  }
);

export const getProcessArtifactCountOfProcess = (id: string) => createSelector(
  getProcessArtifactsOfProcess(id),
  artifacts => artifacts.length
);

export const groupedArtifactsByReferenceId = (id: string) => createSelector(
  CollectorCategorySelectors.getCategoriesByCollectorId(id),
  getAllTasksOfProcess(id),
  CollectorItemSelectors.getAllCollectorItems,
  getProcessArtifactsOfProcess(id),
  TaskResourceSelectors.getAllTaskResources,
  (groups, tasks, items, artifacts, _resources) => {

    const grouping = artifacts.reduce((acc, artifact) => {
      artifact['taskCount'] = tasks.filter(task =>
        task.documents.find(doc => doc.resourceId === artifact.id) ||
        task.attachments.find(attachment => attachment && attachment.documentId === artifact.id)).length;

      (acc[artifact['referenceId']] = acc[artifact['referenceId']] || []).push(artifact);
      return acc;
    }, {});
    return grouping;
  });

export const groupedArtifactsByProcessId = (id: string) => createSelector(
  getAllTasksOfProcess(id),
  getAllProcessArtifacts,
  TaskResourceSelectors.getAllTaskResources,
  (tasks, artifacts, _resources) => {
    return artifacts.reduce((acc, artifact) => {
      artifact['taskCount'] = tasks.filter(task =>
        task.documents.find(doc => doc.resourceId === artifact.id) ||
        task.attachments.filter(a => !!a && a.documentId).find(attachment => attachment && attachment.documentId === artifact.id)).length;
      artifact.processIds.forEach(pid => {
        if (typeof artifact !== 'undefined') (acc[pid] = acc[pid] || []).push(artifact);
      });
      return acc;
    }, {});
  });

export const documentsByCollectoId = (id: string) => createSelector(
  CollectorCategorySelectors.getCategoriesByCollectorId(id),
  CollectorItemSelectors.getAllCollectorItems,
  groupedArtifactsByReferenceId(id),
  (categories, items, grouping) => {
    const documents = [];
    const collectorItems = [];
    let exportedCount = 0;
    items.forEach(item => {
      const itemArtifacts = grouping[item.id];
      if (itemArtifacts) {
        itemArtifacts.forEach(artifact => {
          documents.push({
            id: artifact.id,
            selectId: artifact.id,
            title: item.title,
            exportedBy: artifact.exports,
            fileName: artifact.title,
            dueDate: item.dueDate,
            uploadedAt: artifact.createdAt,
            uploadedBy: artifact.uploaderName,
            uploaderEmail: artifact.uploaderEmail,
            revision: artifact.revision,
            role: artifact.role,
            status: item.status,
            url: artifact.url,
            processId: artifact.processId,
            fileSize: artifact.size,
            taskCount: artifact.taskCount,
            icon: 'playlist_add_check',
            referenceId: item.id,
            isTemplate: artifact.role === 'template',
            itemId: item.id,
            item: item,
            categoryId: item.categoryId,
            color: item.color,
            tags: [],
            bookmanExportedAt: artifact.bookmanExportedAt,
            datevExportedAt: artifact.datevExportedAt,
            datevDocumentId: artifact.datevDocumentId,
            newUpload: artifact.newUpload,
            datevErrorUri: artifact.datevErrorUri,
            datevRequestId: artifact.datevRequestId,
            datevErrorCode: artifact.datevErrorCode,
            datevErrorDescription: artifact.datevErrorDescription
          });
          if (artifact.exports && artifact.exports.length) {
            ++exportedCount;
          }
        });
        collectorItems.push(item);
      }
    });
    return {
      items: collectorItems,
      documents: documents,
      exportedCount: exportedCount
    }
  }
);
export const allCollectoDocsInfo = (id: string) => createSelector(
  getProcessArtifactsOfProcess(id),
  (artifacts) => {
    let exportedCount = 0;
    artifacts.forEach(artifact => {
      if (artifact.exports && artifact.exports.length) {
        ++exportedCount;
      }
    });
    return {
      items: null,
      documents: artifacts,
      exportedCount: exportedCount
    }
  }
);

export const groupedDocumentsByItem = (id: string) => createSelector(
  CollectorCategorySelectors.getCategoriesByCollectorId(id),
  CollectorCategorySelectors.getCollectorCategoryEntities,
  CollectorItemSelectors.getAllCollectorItems,
  documentsByCollectoId(id),
  (categories, categoriesMap, items, collectoDocuments) => {
    const cmap = {};
    categories.map(c => {
      cmap[c.id] = c;
    });
    const collectorItems = collectoDocuments.items;
    const documents = collectoDocuments.documents;

    const groupedDocumentsByItemId = documents.reduce((acc, doc) => {
      (acc[doc['referenceId']] = acc[doc['referenceId']] || []).push(doc);
      return acc;
    }, {});

    const groupMap = {};
    collectorItems.forEach(item => {
      const itemdocs = groupedDocumentsByItemId[item.id];
      if (itemdocs) {
        if (!groupMap[item.categoryId]) {
          groupMap[item.categoryId] = {id: item.categoryId, items: []};
        }
        groupMap[item.categoryId].items.push({
          id: item.id,
          categoryId: item.categoryId,
          title: cmap[item.categoryId] && cmap[item.categoryId].title,
          subTitle: item.title,
          dueDate: item.dueDate,
          status: item.status,
          color: categoriesMap[item.categoryId] && categoriesMap[item.categoryId].color,
          documents: itemdocs,
          order: item.order,
          lockedAt: item.lockedAt
        });
      }
    });
    const groups = [];
    Object.keys(groupMap).forEach(cid => {
      const category = categoriesMap[cid];
      if (category) {
        const _items = groupMap[cid].items;
        const documentCount = _items.reduce((acc, i) => acc + i.documents.length, 0);
        groups.push({
          id: category.id,
          title: category.title,
          items: _items,
          order: category.order,
          color: category.color,
          type: 'quickcollector',
          parentType: 'quickcollector',
          documentCount: documentCount
        });
      }
    });

    return groups.sort((l, r) => ((l.order < r.order) ? -1 : 1));
  });

export const documentsByProjectId = (id: string) => createSelector(
  ProcessSelectors.getNestedProcessesOfRoot(id),
  ProcessSelectors.getProcessById(id),
  groupedArtifactsByProcessId(id),
  (subProcesses, parentProcess, grouping) => {
    const documents = [];
    const items = [];
    let exportedCount = 0;
    subProcesses.forEach(subProcess => {
      let itemArtifacts = grouping[subProcess.id];
      if (subProcess.dmsFolderId === parentProcess.dmsFolderId && subProcess.syncDmsFolder) {
        itemArtifacts = itemArtifacts && itemArtifacts.length > 0 ? itemArtifacts.concat(grouping[subProcess.parentId]) : grouping[subProcess.parentId];
      }
      if (itemArtifacts) {
        itemArtifacts.filter(i => !!i).forEach((artifact) => {
          const isClosed = subProcess.status && subProcess.status.isClosed && subProcess.status.isClosed();
          documents.push({
            id: artifact.id,
            selectId: subProcess.id + artifact.id,
            title: subProcess.title,
            exportedBy: artifact.exports,
            fileName: artifact.title,
            dueDate: subProcess.dueDate,
            uploadedAt: artifact.createdAt,
            uploadedBy: artifact.uploaderName,
            uploaderEmail: artifact.uploaderEmail,
            revision: artifact.revision,
            role: artifact.role,
            status: subProcess.status,
            isClosed: isClosed,
            url: artifact.url,
            processId: artifact.processId,
            fileSize: artifact.size,
            taskCount: artifact.taskCount,
            referenceId: subProcess.id,
            groupId: subProcess.id,
            color: subProcess.color,
            tags: [],
            bookmanExportedAt: artifact.bookmanExportedAt,
            datevExportedAt: artifact.datevExportedAt,
            datevDocumentId: artifact.datevDocumentId,
            newUpload: artifact.newUpload,
            datevErrorUri: artifact.datevErrorUri,
            datevRequestId: artifact.datevRequestId,
            datevErrorCode: artifact.datevErrorCode,
            datevErrorDescription: artifact.datevErrorDescription
          });
          if (artifact.exports && artifact.exports.length) {
            ++exportedCount;
          }
        });
        items.push(subProcess);
      }
    });
    return {
      items,
      documents: documents,
      exportedCount: exportedCount
    }
  }
);

export const groupedDocumentsBySubProject = (id: string) => createSelector(
  ProcessSelectors.getNestedProcessesOfRoot(id),
  documentsByProjectId(id),
  (subProcesses, subProcessesDocs) => {
    const pmap = {};
    subProcesses.map(c => {
      pmap[c.id] = c;
    });
    const subProcessesList = subProcessesDocs.items;
    const documents = subProcessesDocs.documents;

    const groupedDocumentsByReferenceId = documents.reduce((acc, doc) => {
      (acc[doc['referenceId']] = acc[doc['referenceId']] || []).push(doc);
      return acc;
    }, {});
    const subProcessMap = {};
    subProcessesList.forEach((sp: Process) => {

      const subProcessDocuments = groupedDocumentsByReferenceId[sp.id];
      if (subProcessDocuments) {
        if (!subProcessMap[sp.id]) {
          subProcessMap[sp.id] = {id: sp.id, items: []};
        }
        const isClosed = pmap[sp.id].status && pmap[sp.id].status.isClosed && pmap[sp.id].status.isClosed();
        subProcessMap[sp.id].items.push({
          id: sp.id,
          groupId: sp.id,
          title: pmap[sp.id] && pmap[sp.id].title,
          dueDate: sp.due_date,
          color: pmap[sp.id] && pmap[sp.id].color,
          isClosed: isClosed,
          documents: subProcessDocuments,
        });
      }
    });
    const groups = [];
    Object.keys(subProcessMap).forEach(cid => {
      const subProcess = subProcesses.find(sp => cid === sp.id);
      if (subProcess) {
        const _items = subProcessMap[cid].items;
        const documentCount = _items.reduce((acc, i) => acc + i.documents.length, 0);
        const isClosed = subProcess.status && subProcess.status.isClosed && subProcess.status.isClosed();
        groups.push({
          id: subProcess.id,
          title: subProcess.title,
          items: _items,
          order: subProcess.createdAt,
          color: subProcess.color,
          isClosed: isClosed,
          folder: subProcess,
          type: subProcess.processType,
          parentType: 'project',
          documentCount: documentCount
        });
      }
    });

    return groups.sort((l, r) => ((l.order < r.order) ? -1 : 1));
  });

export const documentsOfCollectoExcludeItemsDocs = (id: string) => createSelector(
  ProcessSelectors.getProcessById(id),
  groupedArtifactsByProcessId(id),
  CollectorItemSelectors.getAllCollectorItems,
  (collecto, grouping, collectoItems) => {
    const documents = [];
    const items = [];
    let collectoItemsIds = [];
    let exportedCount = 0;
    const itemArtifacts = grouping[collecto.id];
    if (itemArtifacts) {
      if (collectoItems && collectoItems.length > 0) {
        collectoItemsIds = collectoItems.map(a => a.id);
      }
      itemArtifacts.filter(i => !!i && (!i.referenceId || !collectoItemsIds.includes(i.referenceId))).forEach((artifact) => {
        documents.push({
          id: artifact.id,
          selectId: id + artifact.id,
          title: collecto.title,
          exportedBy: artifact.exports,
          fileName: artifact.title,
          dueDate: collecto.dueDate,
          uploadedAt: artifact.createdAt,
          uploadedBy: artifact.uploaderName,
          revision: artifact.revision,
          role: artifact.role,
          uploaderEmail: artifact.uploaderEmail,
          status: collecto.status,
          url: artifact.url,
          processId: artifact.processId,
          fileSize: artifact.size,
          taskCount: artifact.taskCount,
          referenceId: collecto.id,
          groupId: collecto.id,
          color: collecto.color,
          isUnsortedItem: true,
          tags: [],
          bookmanExportedAt: artifact.bookmanExportedAt,
          datevExportedAt: artifact.datevExportedAt,
          datevDocumentId: artifact.datevDocumentId,
          newUpload: artifact.newUpload,
          datevErrorUri: artifact.datevErrorUri,
          datevRequestId: artifact.datevRequestId,
          datevErrorCode: artifact.datevErrorCode,
          datevErrorDescription: artifact.datevErrorDescription
        });
        if (artifact.exports && artifact.exports.length) {
          ++exportedCount;
        }
      });
      items.push(collecto);
    }
    return {
      items,
      documents: documents,
      exportedCount: exportedCount
    }
  }
);

export const groupedDocumentsForCollecto = (collectoId: string) => createSelector(
  ProcessSelectors.getProcessById(collectoId),
  documentsOfCollectoExcludeItemsDocs(collectoId),
  groupedDocumentsByItem(collectoId),
  (collecto, collectoDocuments, collectoGroups) => {
    const items = {
      id: collecto.id,
      groupId: collecto.id,
      title: collecto.title,
      dueDate: collecto.dueDate,
      color: collecto.color,
      isUnsortedGroup: true,
      documents: collectoDocuments.documents,
    };
    const documentCount = items.documents.length;
    const collectoDocsGroup = [{
      id: collecto.id,
      title: 'COLLECTOR.UNSORTED_UPLOADS',
      items: [items],
      order: collecto.createdAt,
      color: collecto.color,
      folder: collecto,
      type: collecto.processType,
      parentType: null,
      isUnsortedGroup: true,
      documentCount: documentCount
    }];
    return documentCount > 0 ? collectoDocsGroup.concat(collectoGroups) : collectoGroups;
  });

export const getProcessArtifactsOfSelectedProcess = createSelector(
  getAllProcessArtifacts,
  ProcessSelectors.getSelectedProcess,
  (artifacts, process) => {
    if (process) {
      return artifacts.filter(a => !!a.processIds.find(pid => pid === process.id))
    }
    return [];
  });

export const getProcessArtifactOfSelectedProcess = (processId: string, id: string) => createSelector(
  getAllProcessArtifacts,
  (artifacts) => {
    return artifacts.find(a => a.processIds.find(pid => pid === processId) && a.dmsDocumentId === id)
  });

export const getAttachmentsByReferenceMap = createSelector(
  getAllProcessArtifacts,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (artifacts) => {
    const referenceMap = {};
    artifacts.forEach(a => {
      if (!referenceMap[a.referenceId]) {
        referenceMap[a.referenceId] = []
      }
      referenceMap[a.referenceId].push(a);
    })
    return referenceMap;
  }
);

export const getArtifactIdsOfProcessFromStats = (id: string) => createSelector(
  getProcessArtifactState,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (state) => {
    return state
      .statistics
      .filter(a => a?.processId === id)
      .map(a => a.id)
  }
);

export const getUploadsByReferenceMap = createSelector(
  getProcessArtifactState,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (state) => {
    const referenceMap = {};
    state.statistics.forEach(a => {
      if (a.role !== 'template') {
        if (!referenceMap[a.referenceId]) {
          referenceMap[a.referenceId] = []
        }
        referenceMap[a.referenceId].push(a);
      }
    })
    return referenceMap;
  }
);

export const getTemplatesByReferenceMap = createSelector(
  getProcessArtifactState,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (state) => {
    const referenceMap = {};
    state.statistics.forEach(a => {
      if (a.role === 'template') {
        if (!referenceMap[a.referenceId]) {
          referenceMap[a.referenceId] = []
        }
        referenceMap[a.referenceId].push(a);
      }
    })
    return referenceMap;
  }
);

/**
 * Returns a map for new uploads => number for all artifacts inside a referenced group not
 * being a template.
 */
export const getNewUploadsAtReferencedItemMap = createSelector(
  getProcessArtifactState,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (state) => {
    const newUploadAtReference = {};
    state.statistics.forEach(a => {
      if (a.role !== 'template' && a.referenceId) {
        if (!newUploadAtReference[a.referenceId]) {
          newUploadAtReference[a.referenceId] = 0;
        }

        if (a.newUpload) {
          newUploadAtReference[a.referenceId] = newUploadAtReference[a.referenceId] + 1;
        }
      }
    })
    return newUploadAtReference;
  }
);

/**
 * Returns a map for new templates => number for all artifacts inside a referenced group _only_
 * being a template.
 */
export const getNewTemplatesAtReferencedItemMap = createSelector(
  getProcessArtifactState,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (state) => {
    const newUploadAtReference = {};
    state.statistics.forEach(a => {
      if (a.role === 'template' && a.referenceId) {
        if (!newUploadAtReference[a.referenceId]) {
          newUploadAtReference[a.referenceId] = 0;
        }

        if (a.newUpload) {
          newUploadAtReference[a.referenceId] = newUploadAtReference[a.referenceId] + 1;
        }
      }
    });
    return newUploadAtReference;
  }
);

/**
 * Returns the count of new artifacts not being a template.
 * @param id: ID of referenced item e.g. Collecto element, CAC appendix.
 */
export const getNewUploadsCountAtItem = (id: string) => createSelector(
  getNewUploadsAtReferencedItemMap,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (referenceMap) => referenceMap['' + id]
);

/**
 * Returns the count of new artifacts being a template.
 * @param id: ID of referenced item e.g. Collecto element, CAC appendix.
 */
export const getNewTemplatesCountAtItem = (id: string) => createSelector(
  getNewTemplatesAtReferencedItemMap,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (referenceMap) => referenceMap['' + id]
);

/**
 * Returns all artifacts of item with reference ID being teplates.
 * @param id
 */
export const getAttachmentsByReferenceId = (id: string) => createSelector(
  getAttachmentsByReferenceMap,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (referenceMap) => referenceMap['' + id] || []
);

/**
 * Returns all artifacts of item with reference ID not being templates.
 * @param id
 */
export const getUploadsByReferenceId = (id: string) => createSelector(
  getUploadsByReferenceMap,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (referenceMap) => referenceMap['' + id] || []
);

/**
 * Returns all artifacts of item with reference ID being templates.
 * @param id
 */
export const getTemplatesByReferenceId = (id: string) => createSelector(
  getTemplatesByReferenceMap,
  // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
  (referenceMap) => referenceMap['' + id] || []
);

export const getAttachmentsCountByReferenceId = (id: string) => createSelector(
  getAttachmentsByReferenceId(id),
  (artifacts) => artifacts.length
);

export const getContributedByProcessId = (id: string) => createSelector(
  getProcessArtifactsOfProcess(id),
  (artifacts) => artifacts.filter(a => a.role !== 'template')
);

export const getContributedByProcessCount = (id: string) => createSelector(
  getContributedByProcessId(id),
  (artifacts) => artifacts.length
);

export const getAssignedContributedByProcessId = (id: string) => createSelector(
  getProcessArtifactsOfProcess(id),
  (artifacts) => artifacts.filter(a => a.referenceId && a.role !== 'template')
);

export const getAssignedContributedByProcessCount = (id: string) => createSelector(
  getAssignedContributedByProcessId(id),
  (artifacts) => artifacts.length
);

export const getProvidedByProcessId = (id: string) => createSelector(
  getProcessArtifactsOfProcess(id),
  (artifacts) => artifacts.filter(a => a.role === 'template')
);

export const getProvidedByProcessCount = (id: string) => createSelector(
  getProvidedByProcessId(id),
  (artifacts) => artifacts.length
);

export const getProvidedByReferenceId = (id: string) => createSelector(
  getAllProcessArtifacts,
  (artifacts) => {
    if (!id) {
      return [];
    }
    // Cast & convert the reference ID to string. Example Third Party is returning reference IDs as numbers.
    return artifacts.filter(a => a.referenceId === '' + id && a.role === 'template')
  }
);

export const getProvidedByReferenceCount = (id: string) => createSelector(
  getProvidedByReferenceId(id),
  (artifacts) => artifacts.length
);

export const getContributedByReferenceId = (id: string) => createSelector(
  getAttachmentsByReferenceId(id),
  (artifacts) => {
    if (!id) {
      return [];
    }
    return artifacts.filter(a => a.role !== 'template')
  }
);

export const getArtifactsByExternalAccess = (id: string) => createSelector(
  ExternalAccessSelectors.getExternalAccessById(id),
  getAllProcessArtifacts,
  (ea, artifacts) => {
    const documentIds = ea.documentIds;
    const result = [];
    documentIds.forEach(did => {
      const found = artifacts.find(a => a.id === did);
      if (found) result.push(found);
    });
    return result;
  }
);

export const getExternalAccessCountByDocumentId = (id: string) => createSelector(
  ExternalAccessSelectors.getAllExternalAccesss,
  getProcessArtifactEntities,
  (eas, artifacts) => {
    const artifact = artifacts[id];

    if (!artifact.publicAvailable) {
      return 0;
    }

    let count = 0;
    eas.forEach(ea => {
      const found = ea.documentIds.find(did => did === id);
      if (found) {
        ++count;
      }
    });
    return count;
  }
);

export const getContributedByReferenceFirstDate = (id: string) => createSelector(
  getContributedByReferenceId(id),
  (artifacts) => artifacts.sort((a, b) => {
    try {
      if (!a || !b) {
        return 0;
      }
      return a?.createdAt?.getTime() - b?.createdAt?.getTime();
    } catch (e) {
      console.error('[ERROR] Cannot parse date:', a, b)
      return 0;
    }
  })[0]
);

export const getContributedByReferenceCount = (id: string) => createSelector(
  getContributedByReferenceId(id),
  (artifacts) => artifacts.length
);

export const getDocumentById = (id: string) => createSelector(
  getAllProcessArtifacts,
  (docs) => docs.find(doc => doc.id === id)
);

export const getCommentCount = (id: string) => createSelector(
  getProcessArtifactEntities,
  (artifacts) => artifacts[id] && artifacts[id].commentCount
);

export const getUnreadCommentCount = (id: string) => createSelector(
  getProcessArtifactEntities,
  (artifacts) => artifacts[id] && artifacts[id].unreadCommentCount
);

/**
 * Returns the loading state of a collection with ID contextId.
 *
 * @param contextId
 */
export const getCollectionLoadingState = (contextId: string) => createSelector(
  getProcessArtifactState,
  (state) => {
    return !!state.collections[contextId]?.loading;
  }
)

/**
 * Returns all collections of the current state.
 */
export const getCollections = createSelector(
  getProcessArtifactState,
  (state) => {
    return state.collections || {};
  }
)

/**
 * Returns true if the collection has a further page configuration by next token
 * Returns false if the collection is completely loaded or currently loading.
 *
 * @param contextId
 */
export const getCollectionHasNext = (contextId: string) => createSelector(
  getProcessArtifactState,
  (state) => {
    const collection = state.collections[contextId];
    return !!collection &&
      !collection?.loading &&
      !!collection?.next &&
      collection.state === CollectionState.Established;
  }
)

/**
 * Returns the collection records size including not loaded items.
 *
 * @param contextId
 */
export const getCollectionSize = (contextId: string) => createSelector(
  getProcessArtifactState,
  (state) => {
    return state.collections[contextId]?.size || 0;
  }
)

/**
 * Returns the total item count of the current query, e.g. search/filter.
 * As such the total count is always smaller equal to size (all records of collection).
 *
 * @param contextId
 */
export const getCollectionTotal = (contextId: string) => createSelector(
  getProcessArtifactState,
  (state) => {
    return state.collections[contextId]?.total || 0;
  }
)

/**
 * Returns all artifacts of the given collection identified by the IDs list of the collection's store.
 *
 * @param contextId
 */
export const getCollectionByContext = (contextId: string) => createSelector(
  getProcessArtifactState,
  getProcessArtifactEntities,
  (state, entities) => {
    if (!state.collections[contextId]) {
      return [];
    }

    const collection: ProcessArtifact[] = [];
    state.collections[contextId].ids.forEach(id => {
      if (entities[id]) {
        collection.push(entities[id]);
      }
    });
    return collection;
  }
)
