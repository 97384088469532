<ng-container [ngSwitch]="appearance">
  <ng-container *ngSwitchCase="PreviewViewType.Icon">
    <a (click)="$event.preventDefault(); openDialog()"
       href="#" class="ml-1" mat-icon-button
       [matTooltip]="'PREVIEW_BROWSER.DIALOG_TITLE' | translate">
      <mat-icon svgIcon="file_preview" style="font-size: 18px;"></mat-icon>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="PreviewViewType.IconButton">
    <button mat-icon-button
            [matTooltip]="'PREVIEW_BROWSER.DIALOG_TITLE' | translate"
            (click)="openDialog()">
      <mat-icon svgIcon="file_preview"></mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="PreviewViewType.ListItem">
    <fivef-menu-item [icon]="'file_preview'"
                     [disabled]="disabled"
                     [title]="'PREVIEW_BROWSER.DIALOG_TITLE'"
                     (click)="openDialog()"></fivef-menu-item>
  </ng-container>

  <ng-container *ngSwitchCase="PreviewViewType.Custom">
    <div class="mat-dialog-dynamic-width" (click)="openDialog()">
      <ng-content></ng-content>
    </div>
  </ng-container>
</ng-container>

<ng-template #previewBrowserTpl>
  <fivef-artifact-preview-browser [lockedProcess]="lockedProcess"
                                  [processId]="processId"
                                  [viewMode]="viewMode"
                                  [artifact]="artifact"
                                  [startOpen]="true"
                                  [documentId]="documentId"
                                  [documentIds]="documentIds"
                                  (onCreateTask)="onCreateTask.emit($event)"
                                  (onClose)="closeDialog($event)"
                                  [enableTaskCreation]="enableTaskCreation"
                                  [isMember]="isMember$ | async"
                                  (updateDialogueScreen)="adjustFullScreen($event)"
                                  [selectedDocumentPreviewTab]="selectedDocumentPreviewTab"
  ></fivef-artifact-preview-browser>
</ng-template>
