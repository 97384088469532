import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {ArtifactPublicUpload, ArtifactPublicUploadAccess, DmsQueuedArtifact, ProcessArtifact} from './process-artifact';

export class ProcessArtifactBuilder implements IApiResourceBuilder<ProcessArtifact> {
  total = 0;
  records = 0;
  next: string = null;

  constructor(private _processId: string, public recursive = false) {
  }

  fromResponse(data): ProcessArtifact {
    const artifact = new ProcessArtifact(
      data.id,
      this.recursive || !this._processId ? data.attributes.process_id : this._processId,
      data.attributes.organization_id,
      data.attributes.content_type === data.attributes.title ? (data.attributes.file_name || data.attributes.title) : data.attributes.title,
      data.attributes.description,
      data.attributes.revision,
      data.attributes.dms_document_id,
      data.attributes.reference_id,
      data.attributes.role,
      data.attributes.size || data.attributes.file_size,
      data.attributes.uploaded_by || data.attributes.owner_name,
      data.attributes.comment,
      data.attributes.public_available,
      data.attributes.created_at,
      data.attributes.updated_at,
      data.attributes.url,
      data.attributes.process_ids);

    if (data.attributes.uploaded_by_email) {
      artifact.uploaderEmail = data.attributes.uploaded_by_email;
    }

    if (data.attributes.export_markers && data.attributes.export_markers.length > 0) {
      artifact.exports = data.attributes.export_markers.map(exp => {
        return {
          organizationId: exp.organization_uuid,
          performer: exp.performer_email,
          createdAt: exp.created_at
        }
      });
    }

    artifact.newUpload = !!data.attributes.new_upload;
    artifact.keywords = data.attributes.keywords;
    artifact.month = data.attributes.month;
    artifact.year = data.attributes.year;

    artifact.processTitle = data.attributes.process_title;
    artifact.clientName = data.attributes.client_name;
    artifact.clientNo = data.attributes.client_no;

    // Sync attributes, mostly optional.
    artifact.bookmanExportedAt = data.attributes.bookman_exported_at;
    artifact.datevExportedAt = data.attributes.datev_exported_at;
    artifact.datevDocumentId = data.attributes.datev_document_id;
    artifact.dmsObjectIdent = data.attributes.dms_object_ident;
    artifact.datevErrorUri = data.attributes.datev_error_uri;
    artifact.datevRequestId = data.attributes.datev_request_id;
    artifact.datevErrorCode = data.attributes.datev_error_code;
    artifact.datevErrorDescription = data.attributes.datev_error_description;
    artifact.dmsProvider = data.attributes.dms_provider;
    artifact.syncStatus = data.attributes.sync_status;
    artifact.syncedAt = data.attributes.synced_at;

    artifact.commentCount = data.attributes.comment_count;
    artifact.unreadCommentCount = data.attributes.unread_comment_count;
    artifact.newUpload = !!data.attributes.new_upload;
    artifact.documentType = data.attributes.document_type;

    artifact.trackKey = `${artifact.id}|${artifact.newUpload}|${artifact.unreadCommentCount}|${artifact.bookmanExportedAt || artifact.datevDocumentId}`;

    // Pagination by Collection pattern.
    artifact.total = this.total;
    artifact.next = this.next;
    artifact.records = this.records;

    return artifact;
  }

  addMetaSection(meta: any) {
    if (!meta) return;

    this.total = meta.total;
    this.next = meta.next;
    this.records = meta.records;
  }

  toRequest(_: ProcessArtifact) {
    return null;
  }
}

export class ArtifactPublicUploadBuilder implements IApiResourceBuilder<ArtifactPublicUpload> {
  fromResponse(data): ArtifactPublicUpload {
    const attrs = data.attributes;
    return new ArtifactPublicUpload(data.id, attrs.title, attrs.token, attrs.size, attrs.created_at);
  }

  toRequest(_: ArtifactPublicUpload) {
    return null;
  }
}

export class ArtifactPublicUploadAccessBuilder implements IApiResourceBuilder<ArtifactPublicUploadAccess> {
  fromResponse(data): ArtifactPublicUploadAccess {
    const attrs = data.attributes;
    return new ArtifactPublicUploadAccess(data.id, attrs.title, attrs.description, attrs.creatorEmail, attrs.creatorName, attrs.created_at);
  }

  toRequest(_: ArtifactPublicUploadAccess) {
    return null;
  }
}

export class DmsQueuedArtifactBuilder implements IApiResourceBuilder<DmsQueuedArtifact> {
  constructor(private _processId: string) {
  }

  fromResponse(data): DmsQueuedArtifact {
    const attrs = data.attributes;
    const artifact = new DmsQueuedArtifact(
      data.id,
      attrs.title,
      attrs.file_size,
      this._processId,
      attrs.process_title,
      attrs.client_name,
      attrs.client_no,
      attrs.sync_status,
      attrs.dms_object_ident,
      attrs.synced_at,
      attrs.dms_provider,
      attrs.updated_at,
      attrs.created_at);
    return artifact;
  }

  toRequest(_: DmsQueuedArtifact) {
    return null;
  }
}
