import {Update} from '@ngrx/entity';
import {Action} from '@ngrx/store';
import {ProcessArtifact} from './process-artifact';
import {ProcessArtifactCollectionContext} from './process-artifact.collection';

export enum ProcessArtifactActionTypes {
  LoadAll = '[ProcessArtifact] Load All Artifacts',
  LoadAllSuccess = '[ProcessArtifact] Load All Artifacts Success',
  LoadAllFail = '[ProcessArtifact] Load All Artifacts Fail',
  LoadAllExternal = '[ProcessArtifact] Load All External Artifacts',
  LoadAllExternalSuccess = '[ProcessArtifact] Load All External Artifacts Success',
  LoadAllExternalFail = '[ProcessArtifact] Load All External Artifacts Fail',
  LoadOne = '[ProcessArtifact] Load One Artifacts',
  LoadOneSuccess = '[ProcessArtifact] Load One Artifacts Success',
  LoadOneFail = '[ProcessArtifact] Load One Artifacts Fail',
  LoadOneById = '[ProcessArtifact] Load One Artifacts By Id',
  LoadOneByIdSuccess = '[ProcessArtifact] Load One Artifacts Success By Id',
  LoadOneByIdFail = '[ProcessArtifact] Load One Artifacts Fail By Id',
  Delete = '[ProcessArtifact] Delete Artifacts',
  DeleteSuccess = '[ProcessArtifact] Delete Artifacts Success',
  DeleteFail = '[ProcessArtifact] Delete Artifacts Fail',
  PickFileFromDms = '[ProcessArtifact] PickFileFromDms',
  PickFileFromDmsSuccess = '[ProcessArtifact] PickFileFromDms Success',
  PickFileFromDmsFail = '[ProcessArtifact] PickFileFromDms Fail',
  CollectorPickFileFromDms = '[ProcessArtifact] CollectorPickFileFromDms',
  CollectorPickFileFromDmsSuccess = '[ProcessArtifact] CollectorPickFileFromDms Success',
  CollectorPickFileFromDmsFail = '[ProcessArtifact] CollectorPickFileFromDms Fail',
  Reset = '[ProcessArtifact] Reset',
  UpdateOneSuccess = '[ProcessArtifact] Update One Artifact Success',
  MoveAllSuccess = '[ProcessArtifact] Move All Artifacts Success',
  UpdateAllSuccess = '[ProcessArtifact] Update All Artifacts Success',
  Rename = '[ProcessArtifact] Rename Artifact',
  MarkSeen = '[ProcessArtifact] Mark artifact seen',
  MarkSeenSuccess = '[ProcessArtifact] Mark artifact seen success',
  MarkSeenFail = '[ProcessArtifact] Mark artifact seen fail',
  MarkDownloaded = '[ProcessArtifact] Mark artifact downloaded',
  InitOrResetCollection = '[ProcessArtifact] Reset or Init Collection',
  InitOrResetCollectionAlreadyOngoing = '[ProcessArtifact] Reset or Init Collection Ongoing',
  EstablishCollection = '[ProcessArtifact] Establish Collection',
  InitOrResetCollectionSuccess = '[ProcessArtifact] Reset or Init Next Success',
  LoadNext = '[ProcessArtifact] Load Next',
  LoadNextSuccess = '[ProcessArtifact] Load Next Success',
  LoadNextFail = '[ProcessArtifact] Load Next Fail',
  LoadStatistics = '[ProcessArtifact] Load Statistics',
  LoadStatisticsSuccess = '[ProcessArtifact] Load Statistics Success',
  LoadStatisticsFail = '[ProcessArtifact] Load Statistics Fail',
}

/**
 * Requests minimal artifact statistics for all artifacts resources of process with ID.
 */
export class LoadStatistics implements Action {
  readonly type = ProcessArtifactActionTypes.LoadStatistics;

  constructor(public id: string) {
  }
}

/**
 * Loads artifact resource stats into the store and replaces the current statistics data set.
 */
export class LoadStatisticsSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.LoadStatisticsSuccess;

  constructor(public artifacts: ProcessArtifact[]) {
  }
}

/**
 * Dummy action on failed statistics call.
 */
export class LoadStatisticsFail implements Action {
  readonly type = ProcessArtifactActionTypes.LoadStatisticsFail;

  constructor(public erroe: any = null) {
  }
}

/**
 * Inits a new collection.
 *
 * If a collection is already created by the reducer then the recreation is skipped except
 * the argument reset is set to true.
 * The argument must be set to true for forced reinitialization in search or filter cases on existing collections.
 *
 * Initial API calls are always invoked with including query parameters and without next token.
 */
export class InitOrResetCollection implements Action {
  readonly type = ProcessArtifactActionTypes.InitOrResetCollection;

  constructor(public context: ProcessArtifactCollectionContext, public reset = false) {
  }
}

/**
 * Dummy action to prevent double invocation of the API (parallel) API calls for the initializing phase of collections.
 * See effect and reducer for more details.
 */
export class InitOrResetCollectionAlreadyOngoing implements Action {
  readonly type = ProcessArtifactActionTypes.InitOrResetCollectionAlreadyOngoing;

  constructor(public context: ProcessArtifactCollectionContext, public reset = false) {
  }
}

/**
 * Establishes a collection at the store.
 */
export class EstablishCollection implements Action {
  readonly type = ProcessArtifactActionTypes.EstablishCollection;

  constructor(public context: ProcessArtifactCollectionContext) {
  }
}

/**
 * Initialization of a store with API response.
 * Replaces existing collections for the same context ID.
 */
export class InitOrResetCollectionSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.InitOrResetCollectionSuccess;

  constructor(public context: ProcessArtifactCollectionContext, public artifacts: ProcessArtifact[], public nextToken: string) {
  }
}

/**
 * Loads `next page` of a collection by the next token.
 * Query parameters are skipped on next calls to keep the context consistent.
 */
export class LoadNext implements Action {
  readonly type = ProcessArtifactActionTypes.LoadNext;

  constructor(public context: ProcessArtifactCollectionContext) {
  }
}

/**
 * Adds API response items to the collection with context ID.
 */
export class LoadNextSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.LoadNextSuccess;

  constructor(public context: ProcessArtifactCollectionContext, public artifacts: ProcessArtifact[], public nextToken: string) {
  }
}

/**
 * Fail case for paginated API calls.
 */
export class LoadNextFail implements Action {
  readonly type = ProcessArtifactActionTypes.LoadNextFail;

  constructor(public context: ProcessArtifactCollectionContext, public errors: any = null) {
  }
}

export class LoadAll implements Action {
  readonly type = ProcessArtifactActionTypes.LoadAll;

  constructor(public id: string, public recursive = false, public loading = true, public upsertEntities = false) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.LoadAllSuccess;

  constructor(public payload: ProcessArtifact[], public upsertEntities = false) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ProcessArtifactActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class LoadAllExternal implements Action {
  readonly type = ProcessArtifactActionTypes.LoadAllExternal;

  constructor(public token: string) {
  }
}

export class LoadAllExternalSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.LoadAllExternalSuccess;

  constructor(public payload: ProcessArtifact[]) {
  }
}

export class LoadAllExternalFail implements Action {
  readonly type = ProcessArtifactActionTypes.LoadAllExternalFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = ProcessArtifactActionTypes.LoadOne;

  constructor(public processId: string, public id: string) {
  }
}

/**
 * Inserts artifact into store.
 * ATTENTION: This action has connected listening actions of other stores and is therefore expensive.
 */
export class LoadOneSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.LoadOneSuccess;

  constructor(public artifact: ProcessArtifact) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ProcessArtifactActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class LoadOneById implements Action {
  readonly type = ProcessArtifactActionTypes.LoadOneById;

  constructor(public id: string) {
  }
}

export class LoadOneByIdSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.LoadOneByIdSuccess;

  constructor(public artifact: ProcessArtifact) {
  }
}

export class LoadOneByIdFail implements Action {
  readonly type = ProcessArtifactActionTypes.LoadOneByIdFail;

  constructor(public payload: any) {
  }
}

/**
 * Inserts artifact into store.
 * ATTENTION: This action has connected listening actions of other stores and is therefore expensive.
 */
export class UpdateOneSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.UpdateOneSuccess;

  constructor(public artifact: ProcessArtifact) {
  }
}

export class Delete implements Action {
  readonly type = ProcessArtifactActionTypes.Delete;

  constructor(public processId: string, public id: string, public deleteFile = false) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.DeleteSuccess;

  constructor(public id: string) {
  }
}

export class DeleteFail implements Action {
  readonly type = ProcessArtifactActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class PickFileFromDms implements Action {
  readonly type = ProcessArtifactActionTypes.PickFileFromDms;

  constructor(public processId: string, public referenceId: string, public dmsDocumentId: string, public dmsAccountType) {
  }
}

export class PickFileFromDmsSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.PickFileFromDmsSuccess;

  constructor(public artifact) {
  }
}

export class PickFileFromDmsFail implements Action {
  readonly type = ProcessArtifactActionTypes.PickFileFromDmsFail;

  constructor(public payload: any = null) {
  }
}

export class CollectorPickFileFromDms implements Action {
  readonly type = ProcessArtifactActionTypes.CollectorPickFileFromDms;

  constructor(public collectorId: string, public nodeId: string, public role: string, public dmsDocumentId: string, public dmsAccountType) {
  }
}

export class CollectorPickFileFromDmsSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.CollectorPickFileFromDmsSuccess;

  constructor(public artifact) {
  }
}

export class CollectorPickFileFromDmsFail implements Action {
  readonly type = ProcessArtifactActionTypes.CollectorPickFileFromDmsFail;

  constructor(public payload: any = null) {
  }
}

export class Reset implements Action {
  readonly type = ProcessArtifactActionTypes.Reset;

  constructor() {
  }
}

export class MoveAllSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.MoveAllSuccess;

  constructor(public payload: ProcessArtifact[]) {
  }
}

export class UpdateAllSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.UpdateAllSuccess;

  constructor(public payload: Update<ProcessArtifact>[]) {
  }
}

export class Rename implements Action {
  readonly type = ProcessArtifactActionTypes.Rename;

  constructor(public processId: string, public id: string, public fileName: string) {
  }
}

/**
 * Marks a given artifact as seen by API request.
 * This request is marking all upload events to the given document for the
 * current user as seen.
 *
 * Used at preview requests to remove new upload indicator.
 */
export class MarkSeen implements Action {
  readonly type = ProcessArtifactActionTypes.MarkSeen;

  constructor(public id: string) {
  }
}

/**
 * Success on API seen request.
 *
 * Used at preview requests to remove new upload indicator.
 */
export class MarkSeenSuccess implements Action {
  readonly type = ProcessArtifactActionTypes.MarkSeenSuccess;

  constructor(public id: string) {
  }
}

/**
 * Failure on seen request.
 *
 * Used at preview requests to remove new upload indicator.
 */
export class MarkSeenFail implements Action {
  readonly type = ProcessArtifactActionTypes.MarkSeenFail;

  constructor(public payload: any = null) {
  }
}

/**
 * Mark a given artifact seen if in store.
 * See reducer for implementation.
 *
 * Used at download requests to remove new upload indicator.
 */
export class MarkDownloaded implements Action {
  readonly type = ProcessArtifactActionTypes.MarkDownloaded;

  constructor(public id: string) {
  }
}

export type ProcessArtifactActions =
  | UpdateAllSuccess
  | CollectorPickFileFromDms
  | CollectorPickFileFromDmsSuccess
  | CollectorPickFileFromDmsFail
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | LoadOneById
  | LoadOneByIdSuccess
  | LoadOneByIdFail
  | UpdateOneSuccess
  | Delete
  | DeleteSuccess
  | DeleteFail
  | LoadAllExternal
  | LoadAllExternalSuccess
  | PickFileFromDms
  | PickFileFromDmsSuccess
  | PickFileFromDmsFail
  | LoadAllExternalFail
  | Reset
  | MoveAllSuccess
  | Rename
  | MarkSeen
  | MarkSeenSuccess
  | MarkSeenFail
  | MarkDownloaded
  | InitOrResetCollection
  | InitOrResetCollectionAlreadyOngoing
  | EstablishCollection
  | InitOrResetCollectionSuccess
  | LoadNext
  | LoadNextSuccess
  | LoadNextFail
  | LoadStatistics
  | LoadStatisticsSuccess
  | LoadStatisticsFail;
